import React from 'react';
import { Analytics } from '@vercel/analytics/react';
import { headerData } from '../data/pageData';

import { Navigation, NavigationMobile } from '../components/Navigation'
import { Footer } from '../components/Footer'

export function NoPage() {
    return (
        <div className='app'>
            <div className='top'>
                <Navigation data={headerData}/>
                <h1>404</h1>
            </div>
            <Footer />
            <Analytics />
        </div>
    )
}

export function NoPageMobile() {
    return (
        <div className='app'>
            <div className='top'>
                <NavigationMobile data={headerData}/>
            </div>
            <Footer />
            <Analytics />
        </div>
    )
}