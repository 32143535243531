function ProjectElemetsGenerator({ data }) {
    console.log(data)
    const projekter = data.projekter
    return (
        <>
            {projekter.map(projekt => (
                <div className="begreb_frame">
                    <h1 className="begreb">{projekt.projektTitle}</h1>
                    <div className="udvidet_information">
                        <h2 className="begreb_information" >Hvad var formålet?</h2>
                        <p className="indepth_information" style={{ whiteSpace: 'pre-line' }}>
                            {projekt.projektFormål}
                        </p>
                    </div>
                    <div className="udvidet_information">
                        <h2 className="begreb_information">Hvordan løste vi opgaven?</h2>
                        <p className="indepth_information">
                            {projekt.projektOurSolution}
                        </p>
                    </div>
                    <div className="udvidet_information">
                        <h2 className="begreb_information">Beskrivelse af løsning - med billeder</h2>
                        <p className="indepth_information">
                            {projekt.projektSolutionDescription}
                        </p>
                    </div>
                </div>
            ))}
        </>
    );
}

export function ProjekterComp({ data }) {
    return (
        <div>
            <ProjectElemetsGenerator data={data} />
            {/*
            <div className="begreb_frame">
                <h1 className="begreb">ERP-Systemer - Dinero</h1>
                <div className="udvidet_information">
                    <h2 className="begreb_information">Hvad var formålet?</h2>
                    <p className="indepth_information">
                        Indsæt tekst her
                    </p>
                </div>
                <div className="udvidet_information">
                    <h2 className="begreb_information">Hvordan løste vi opgaven?</h2>
                    <p className="indepth_information">
                        Begrebet dækker over en analysemodel med 4 underliggende kategorier: <br />
                    </p>
                </div>
                <div className="udvidet_information">
                    <h2 className="begreb_information">Beskrivelse af løsning - med billeder</h2>
                    <p className="indepth_information">
                        Indsæt tekst her
                    </p>
                </div>
            </div>
    */}
        </div>
    )
}

export default ProjekterComp