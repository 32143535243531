import React from 'react';
import { Analytics } from '@vercel/analytics/react';
import { headerData } from '../data/pageData';

import { Navigation, NavigationMobile } from '../components/Navigation'
import { Hero } from '../components/Hero'
import { Footer } from '../components/Footer'

export function HomePage() {
    return (
        <div className='app'>
            <div className='top'>
                <Navigation data={headerData}/>
                <Hero />
            </div>
            <Footer />
            <Analytics />
        </div>
    )
}

export function HomePageMobile() {
    return (
        <div className='app'>
            <div className='top'>
                <NavigationMobile data={headerData}/>
                <Hero />
            </div>
            <Footer />
            <Analytics />
        </div>
    )
}