const text = `På denne side findes et udplud af mit arbejde i faget Erhvervsinformatik C.
Benyt navigationsbaren øverst på siden til at besøge de forskellige kategorier!`

export function Hero() {
    return (
        <div className="hero">
            <div className="frontpagetext">
                <h1 className="welcome_text">
                    Velkommen til <br/> 
                    <span className="orange_text">Asgers</span> Portfolie
                </h1>
                <p className="indepth_information fontpagedescription" style={{ whiteSpace: 'pre-line' }}>
                    {text}
                </p>
                <img className="frontpage_signature" src="https://zjgpqahpxlidtfxsxeah.supabase.co/storage/v1/object/sign/testing_1/Underskrift.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ0ZXN0aW5nXzEvVW5kZXJza3JpZnQucG5nIiwiaWF0IjoxNzE4NTQyOTkzLCJleHAiOjE3NTAwNzg5OTN9.NrXqSKUoIlPvvSZ1gH73o7x_srd7SvikxIBjIoeCWhA&t=2024-06-16T13%3A03%3A14.030Z" alt="asger_signature" width="25%"/>
                <p className="indepth_information">
                    - Asger Andersen
                </p>
            </div>
            <img src="https://zjgpqahpxlidtfxsxeah.supabase.co/storage/v1/object/sign/testing_1/portfolio_image.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ0ZXN0aW5nXzEvcG9ydGZvbGlvX2ltYWdlLnBuZyIsImlhdCI6MTcxODU0MTk3NiwiZXhwIjoxNzUwMDc3OTc2fQ.AWIxo9-VwXCmNRLYwUt0kLG6IPHALvGkLvwWhNOqMTA&t=2024-06-16T12%3A46%3A16.995Z" alt="front_image" draggable="false" width="40%"/>
        </div>
    )
}

export default Hero