import React from 'react';
import { Analytics } from '@vercel/analytics/react';
import { headerData } from '../data/pageData';

import { Navigation, NavigationMobile } from '../components/Navigation'
import { Footer } from '../components/Footer'

export function OversigtPage() {
    return (
        <div className='app'>
            <div className='top'>
                <Navigation data={headerData}/>
                <h1 className="page_headline">Oversigt</h1>
                <div className='oversigt_image'>
                    <img src='https://zjgpqahpxlidtfxsxeah.supabase.co/storage/v1/object/sign/testing_1/Erhvervsinformatik_C_-_Oversigt.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ0ZXN0aW5nXzEvRXJodmVydnNpbmZvcm1hdGlrX0NfLV9PdmVyc2lndC5wbmciLCJpYXQiOjE3MTg1NTczODIsImV4cCI6MTc1MDA5MzM4Mn0.B6oYj1-f-EY3VCSv0Bfu1mat1hl2j9YdcB8kkK5ijhk&t=2024-06-16T17%3A03%3A02.439Z' alt='erhvervsinformatik_oversigt' draggable='false' width='90%'></img>
                </div>
            </div>
            <Footer />
            <Analytics />
        </div>
    )
}