export const headerData = {
    "elements": [
        {
            "element": "Kompetenceområder",
            "path": "/kompetenceområder",
            "value": "kompetenceområder",
            "subpages": [
                {
                    "element": "Digital Myndiggørelse",
                    "path": "/kompetenceområder/digital-myndiggørelse",
                    "value": "digital-myndiggørelse",
                    "data": [
                        {
                            "title": "",
                            "text": "Digital myndiggørelse omhandler kritisk, refleksiv og konstruktiv undersøgelse af betydningen af indførelse af digital teknologi og automatisering i de erhverv, som eleverne uddanner sig til, herunder forståelse for sikkerhed, etik og konsekvenser ved digitale teknologier."
                        }
                    ]
                },
                {
                    "element": "Erhvervsrettet Digital Udvikling",
                    "path": "/kompetenceområder/erhvervsrettet-digital-udvikling",
                    "value": "erhvervsrettet-digital-udvikling",
                    "data": [
                        {
                            "title": "",
                            "text": "Erhvervsrettet digital udvikling omhandler konkret praktisk arbejde, som leder frem mod udvikling eller redesign af digitale artefakter, herunder tilrettelæggelse og gennemførelse af iterative designprocesser samt modifikation og videreudvikling af digitale artefakter med relevans i en erhvervsfaglig kontekst."
                        }
                    ]
                },
                {
                    "element": "Teknologisk Handleevner og Computationel Tankegang",
                    "path": "/kompetenceområder/teknologisk-handleevner-og-computationel-tankegang",
                    "value": "teknologisk-handleevner-og-computationel-tankegang",
                    "data": [
                        {
                            "title": "",
                            "text": "Teknologisk handleevne og computationel tankegang omhandler anvendelse af grundlæggende viden om netværk, forståelse af algoritmiske forskrifter, programmering, logisk og algoritmisk tænkning, abstraktion og mønstergenkendelse, datamodellering samt test og afprøvning."
                        }
                    ]
                }
            ]
        },
        {
            "element": "Projekter",
            "path": "/projekter",
            "value": "projekter",
            "subpages": [
                {
                    "element": "ERP-System - Dinero",
                    "path": "/projekter/erp-system-dinero",
                    "value": "erp-system-dinero",
                    "data": [
                        {
                            "title": "Hvad var formålet?",
                            "text": `Projektet omkring ERP-systemer dykkede helt konkret ned i ERP-systemet Dinero, og hvordan især mindre og mellemstore virksomheder kan bruge det i hverdagen.
                            
                            Det overordnede projekt var delt i to opgaver, der hver især havde underliggende opgaver. De to overordnede opgaver var administrative opgaver i hhv. Excel og Dinero. 
                            
                            Underopgaverne bestod af basale administrative opgaver som at udarbejde produktdatabase, indkøbsdatabase, kundedatabase, ordredatabase, lagerbeholdning og fakturaer.                             
                            
                            Formålet med projektet var i sidste ende at udføre de samme opgaver i to forskellige programmer, for at få en dybere forståelse for hvordan ERP-systemer fungerer, og hvorfor de er smarte at bruge.`,
                        },
                        {
                            "title": "Hvordan løste vi opgaven?",
                            "text": `Opgaven var som sagt delt i to, hvor vi skulle udføre mere eller mindre samme administrative opgaver i både Excel og Dinero. I excel startede vi med at oprette forskellige databaser og udfylde dem med diverse information fra bl.a. tildelte fakturaer.
                            
                            Her blev det hurtigt klart, at de samme data gik igen på tværs af de forskellige databaser, og der derfor foregik meget spildarbejde.
                            
                            Efterfølgende udførte vi samme opgaver, men i Dinero. Her var det tydeligt at arbejdet foregik mere effektivt, mindre smertefrit og mindre stressende - det var kort sagt mere overskueligt, og mange af de allerede indtastede data kunne tilgås under de forskellige funktioner.                 
                            
                            Udførslen af opgaverne i Dinero endte ud med en stak færdiggjorte fakturaer og en let oversigt over alle registrerede indtægter samt udgifter.`,
                        },
                        {
                            "title": "Beskrivelse af løsning - med billeder",
                            "text": `Vores løsning, eller det endelige resultat af projektet ses her. Det består af billeder fra både første del og anden del - altså udklip fra det forsimplede ERP-system i Excel samt udklip fra Dinero og det resultat vi endte ud med der.`,
                            "media": [
                                {
                                    "text": "Udklip af vores kundedatabase ses her:",
                                    "title": "Udklip af kundedatabase.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=e451abe2-243c-4314-85d2-cd8f557dc43e",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": "Her ses et udklip fra vores varedatabase:",
                                    "title": "Udklip af varedatabase.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=c0b8aa3f-7525-4b5b-a0d3-bacb68d75204",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": "Og her ses et udklip fra vores ordredatabase:",
                                    "title": "Udklip af ordredatabase.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=a66a04e5-5e39-490e-963c-dea7b4f8821c",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": "Her ses et udklip fra vores dashboard i Dinero:",
                                    "title": "Udklip af Dinero Dashboard.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=c2dd7a0b-f1ad-40e3-a374-822a161d2a07",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": "Her ses et udklip fra vores sendte fakturaer:",
                                    "title": "Udklip af sendte fakturaer.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=9735ff25-2298-4d63-9870-5c8063fb227c",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": "Her ses et udklip fra registrerede varekøb:",
                                    "title": "Registrerede varekøb.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=6017bdd0-7482-42da-b130-7fb41824e417",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": "Her ses et udklip fra vores CRM-system:",
                                    "title": "Udklip fra CRM-modul.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=a83b37a3-2c91-4410-9a4d-28ff3909577c",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": "Et eksempel på vore udarbejdede fakturaer ses her:",
                                    "title": "Eksempel på faktura.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=7452a10d-44fc-416a-a08a-e9184c789479",
                                    "width": "640",
                                    "height": "360"
                                }
                            ]
                        },
                    ]
                },
                {
                    "element": "Programmering",
                    "path": "/projekter/programmering",
                    "value": "programmering",
                    "data": [
                        {
                            "title": "Hvad var formålet?",
                            "text": `Under programmeringsworkshoppen skulle vi, på vegne af sport24, udarbejde vores egen sundhedsapp. Sundhedsappen skulle først og fremmest have en BMI-måler eller beregner, som brugeren kan benytte.
                            
                            Derudover indsamlede vi data om klassens størrelser ift. højde, vægt og tøjstørrelser, som derefter skulle analyseres og bruges til at udarbejde personliggjorte reklamer i appen.
                            
                            Sidst men ikke mindst skulle vi selv ideudvikle og komme op med ideer til funktioner der kan tilføjes til appen, fastholde brugerne, og derigennem bidrage til sport24s forretning.
                            
                            Formålet med hele projektet var først og fremmest at få en større indsigt i programmering og hvordan værktøjer som code.org’s AppLab kan bruges til at udarbejde prototyper. Derudover kunne vi afprøve flere af teorierne omkring både designprocesser som den iterative designproces, Interaktionsdesign, herunder designregler ift. brugervenlighed og netværksarkitektur, som i forbindelse med sundsheds-appen er 3-lags-arkitektur.
                            
                            Data var ikke det største omdrejningspunkt for projektet, men vi skulle stadig stifte bekendskab med det, da næste projekt dykker ned i det.`,
                        },
                        {
                            "title": "Hvordan løste vi opgaven?",
                            "text": `For at løse opgaven udarbejde vi, på baggrund af vores mockup og kravsspecifikation, en prototype i AppLab, som havde fokus på brugervenlighed, hvor vi gennem designregler som first-things-first, loven om lighed og loven om nærhed. Derudover brugte vi fonte og farver i et forsøgte på at holde sport24s visuelle identitet. `,
                        },
                        {
                            "title": "Beskrivelse af løsning - med billeder",
                            "text": `Vores endelige løsning indeholder tre sider. En forside, bmi-måler og daily-challenge.`,
                            "media": [
                                {
                                    "text": `"Daily Challenge" siden er ekstrafunktionen vi valgte at tilføje til appen. Funktionen har til formål at bidrage til brugerens hverdag, så brugeren har et behov eller ønske for at besøge appen dagligt.
                                    
                                    Først laves en wireframe / mockup af siden i appen:`,
                                    "title": "BMI Beregner - Version 1.pdf",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=e2cda108-4f5c-4b93-ad39-95686b2ba8e8",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `På nuværende tidspunkt havde vi altså gennemgået problemfasen, idefasen og konstruktionsfasen af den iterative designprocess.
                                    
                                    Tilbage var blot vurderingen. Ideen var der helt klart, men den manglede et eller andet til at inddrage brugeren mere i aktiviteten.
                                    
                                    Hvorfor ikke gøre det ved at give brugeren mulighed for at indtaste en score, så vi kan give brugeren feedback og lave en rangliste?
                                    
                                    Funktionen opdateres altså med den nye ide - se mockuppen her:`,
                                    "title": "BMI beregner - Version 1.1.pdf",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=a6edf5e5-3c2f-4c1f-ac96-03e3e0fdf0b3",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `Efter alle sider var planlagt og designet, konstruerede vi en prototype i applab. Siderne af prototypen præsenteres nedenfor.
                                    
                                    Forsiden ses på billedet nedenfor, og består af sektionerne: Header, Reklame og Navigation. 
                                    
                                    Her får brugerens præsenteret virksomheden bag appen, en reklame, der tilpasses på baggrund af brugerens oplysninger, og en navigationssektion, som giver brugeren adgang til de primære funktioner i appen.`,
                                    "title": "Programmering - forside.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=730b42c8-904c-4180-b84c-c30a62dcb2ad",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `Vores BMI-måler består af to input-bokse, hvor brugeren kan indtaste deres oplysninger og derefter får deres BMI-værdi og kategori præsenteret. På baggrund af resultatet tilpasses reklamen på forsiden, så den passer til brugerens størrelse.`,
                                    "title": "Programmering - BMI Beregner.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=69845e5e-e6a6-465b-adf1-a56562e537af",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `Kravspecifikation og mockup for daily-challenge funktionen, samt det endelige resultat ses nedenfor. Ved daily-challenge modtager brugeren, som det ligger i navnet, en daglig udfordring. Udfordringen består af en lille video samt en beskrivelse, hvorefter brugeren kan indtaste deres resultat af udfordringen og modtager feedback på hvordan de kan forbedre det fremover - funktionen er ikke 100% færdigudviklet, men konceptet ses i appen.`,
                                    "title": "Programmering - Daily Challenge.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=d310f10f-85e8-4988-ae5f-d5e1b2c379a4",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `Vores endelige prototype og logiklaget (koden), med kommentarer, findes her:`,
                                    "link": "https://studio.code.org/projects/applab/NRtEOLPXiX0XjPsyEzVbiZ_shdyTcuZULP5KuS1NdCU"
                                },
                            ]
                        },
                    ]
                },
                {
                    "element": "Designprocessen - Egen Hjemmeside",
                    "path": "/projekter/designprocessen",
                    "value": "designprocessen",
                    "data": [
                        {
                            "title": "Hvad var formålet?",
                            "text": `Projektet havde fokus på designprocesser, helt konkret den iterative designproces, og alt det indebærer. Gennem forløbet skulle vi prøve kræfter med blandt andet interaktionsdesign, mockups, kravsspecifikation og en prototype. 
                            
                            Projektet tog udgangspunkt i gruppernes egne hjemmesider, som skulle udarbejdes på baggrund af det overordnede tema: “En guide til EUX”.
                            
                            Overordnet set var formålet med forløbet altså at give os en mulighed for at prøve kræfter med selv at udarbejde både mockups, kravsspecifikationer og prototyper. Derudover skulle vi også udarbejde en prototype på baggrund af andre gruppers mockups samt kravsspecifikationer, for at få indsigt i de udfordringer det eventuelt kan inkludere.`,
                        },
                        {
                            "title": "Hvordan løste vi opgaven?",
                            "text": `Opgaven tog start ved at vi, gennem et dybdegående interview med Chat-GPT, fik sporet os ind på indholdet, som hjemmesiden skulle opbygges omkring.
                            
                            Da indholdet var på plads, udarbejdede vi siden i google sites. Derefter lavede vi mockups af strukturen på de forskellige undersider, kravsspecifikationer til undersiderne og en database med både tekst og billeder til hjemmesiden.
                           
                            Da det hele var færdigt, skulle vi, med udgangspunkt i en anden gruppes mockup og kravsspecifikation, udarbejde en hjemmeside.`,
                        },
                        {
                            "title": "Beskrivelse af løsning - med billeder",
                            "text": `Udklip fra hjemmesiden ses på billederne nedenfor:`,
                            "media": [
                                {
                                    "text": `Vores endelige løsning, eller endelige prototype kan ses til fulde på linket her: `,
                                    "link": "https://sites.google.com/view/eux-guiden/forside",
                                },
                                {
                                    "text": `Til siden udarbejdede vi som sagt mockups og en kravsspecifikation. Det kan findes på dette link:`,
                                    "link": `https://campusvejle-my.sharepoint.com/:f:/g/personal/57656_edu_campusvejle_dk/EhB8hCHZaclOgwXmX--S8QIBsWhzQ0NBeJQDjPy_AePHwA?e=b51sIw`,
                                },
                                {
                                    "text": `Uddrag af mockups ses nedenfor:`,
                                    "title": "Mockup af forside.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=fd1b9b04-285e-4a99-ad25-a63bb8dadf4a",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "title": "Mockup af eksamensforberedelse.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=89cc9e7d-85e2-4e2a-a576-3e2fc016247c",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "title": "EUX Guiden - Forside.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=2cc80c6f-eac1-4447-b160-bfdac95924f0",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "title": "EUX Guiden - Sektioner.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=48e34ce0-a2be-46ac-a8d5-3ed771804c22",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "title": "EUX Guiden - Studieteknikker.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=f165eb9a-0d25-4a63-84cc-e035b3553fac",
                                    "width": "640",
                                    "height": "360"
                                },
                            ]
                        },
                    ]
                },
                {
                    "element": "Kickstart - Interaktionsdesign",
                    "path": "/projekter/kickstart-interaktionsdesign",
                    "value": "kickstart-interaktionsdesign",
                    "data": [
                        {
                            "title": "Hvad var formålet?",
                            "text": `Vi arbejdede over en periode med kickstart programme projektet, hvor vi skulle opbygge et koncept for en virksomhed, på baggrund af vores grundlæggende ide.

                            I forbindelse med projektet skulle vi udarbejde en hjemmeside, hvor interesserede kunne læse og lære mere om os. I den forbindelse benyttede vi forskellige designregler til at gøre siden så overskuelig og let tilgængelig som muligt.`,
                        },
                        {
                            "title": "Hvordan løste vi opgaven?",
                            "text": `For at gøre siden så brugervenlig som muligt, arbejdede vi med mange af de forskellige designregler, herunder first things first, keep it simple and straight, samt gestaltlovene. Helt konkret de fem love der arbejdes med i erhvervsinformatik-faget, nemlig loven om lighed, loven om nærhed, loven om lukkethed, loven om forbundethed og loven om figur og baggrund.

                            Eksempler på reglerne og hvordan de er brugt fremgår nedenfor.`,
                        },
                        {
                            "title": "Beskrivelse af løsning - med billeder",
                            "text": `Et uddrag af forsiden på vores endelige hjemmesidekoncept ses her:`,
                            "media": [
                                {
                                    "text": ``,
                                    "title": "Blankt koncept.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=22be5284-e6a3-41c1-9de2-6087c3586dd2",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `Ved illustrationen her, brydes siden ned i nogle af de konkrete designregler vi tog i brug:`,
                                    "title": "Designregler - Eksempel.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=017e18f0-3455-4292-a51e-cbdc997fe277",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `For at alle var klar over hvordan siden skulle fungere og hvilke krav vi havde til den, udarbejdede vi en grov kravsspecifikation ift. de væsentlige elementer:`,
                                    "title": "Grov kravsspecifikation.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=0ea835a3-afce-4c11-8ce7-02317eed8822",
                                    "width": "640",
                                    "height": "360"
                                },
                            ]
                        },
                    ]
                },
                {
                    "element": "Databaser",
                    "path": "/projekter/databaser",
                    "value": "databaser",
                    "data": [
                        {
                            "title": "Hvad var formålet?",
                            "text": `Under projektet med databaser skulle vi først og fremmest lære om opbygningen af databaser, og hvilke typer af databaser der findes. 
                            
                            Derefter arbejdede vi med opgaver omkring både flade databaser og relationsdatabaser - her var formålet at lære om potientielle fejl og overflødig information i de flade databaser. 
                            
                            Herefter skulle vi, ved at arbejde med relationsdatabaser, lære om primære nøgler og *E/R Diagrammer* over kommunikationen mellem tabellerne i databasen.
                            
                            Den afsluttende opgave indebar datasøgning i en SQL-database, hvor formålet var at få et indblik i hvordan SQL-kode fungerer, og hvordan data fra de forskellige tabeller kan bruges i samspil.
                            
                            Alt i alt var formålet med projektet at få et større indblik i databaser, hvordan de er opbygger og hvordan man kan arbejde med dem.`,
                        },
                        {
                            "title": "Hvordan løste vi opgaven?",
                            "text": `Opgaveløsningen bestod primært af at læse og undersøge de forskellige begreber der indgår i arbejdet med databaser. Det løste vi ved at læse i systime-bogen.
                            
                            Derudover udarbejdede vi et E/R Diagram i Figma, for at forstå sammenhængen mellem de forskellige tabeller i vores relationsdatabase. 
                            
                            Den afsluttende opgave løste vi ved at installere SQL Lite for at søge information i databasen.
                            
                            Efterfølgende har vi arbejdet videre med databaser. Her udarbejdede vi blandt andet vores egen SQL database på baggrund af dataene der indgik i vores ERP-systemer workshop, med Dinero.
                            
                            Databasens struktur fastlagde vi på baggrund af de givne tabeller fra Dinero-projektet. Her opstillede vi ved hjælp af et E/R diagram strukturen over tabellerne i databasen.
                            
                            For at få et større indblik i databaserne og deres sammenhæng, søgte og sorterede vi data ved hjælp af SQL-koder.`,
                        },
                        {
                            "title": "Beskrivelse af løsning - med billeder",
                            "text": `Der er ikke så meget visuelt ift. de første, undersøgende og beskrivende opgaver.`,
                            "media": [
                                {
                                    "text": `Det E/R diagram jeg udarbejdede under workshoppen ift. vores relationsdatabase ses nedenfor:`,
                                    "title": "E_R Diagram - Databaser.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=365f714a-d1b4-499e-acb7-dc77d0c9b580",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `I E/R diagrammet kan vi udlede, at mange kunder kan bo i en by, 1 kunde kan have mange lejeaftaler ved firmaet, og der kan være mange lejeaftaler på den samme, ene, bil. Derudover kan der indgå mange biler i en bilkategori.
                                    
                                    I billedet nedenfor ses en af de SQL-sætninger vi arbejdede med under workshoppen. Her søgte vi blandt de forskellige biler og deres tilhørende lejeaftaler. På baggrund af lejeaftalernes start- og slutdatoer samt den registrerede prisperdag for de forskellige biler, beregnede vi omsætningen.
                                    
                                    Derefter koblede vi de forskellige kategorier på, og sorterede omsætningen efter bilmodellerne, hvorefter vi kunne aflæse hvilken bilmodel der har genereret den største omsætning.
                                    
                                    SQL søgningen og koden fremgår af billedet nedenfor:`,
                                    "title": "SQL Kode - Databaser.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=ed56a743-0671-4364-b3f0-050746e45c83",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `Her fremgår E/R diagrammet for det efterfølgende projekt, hvor vi selv udarbejdede en større og mere kompleks SQL-database.`,
                                    "title": "ER Diagram - Eksempel.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=c0655f72-c4a9-4eef-8ff4-03bc578324c2",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `Tabellerne i den SQL-database vi selv udarbejdede efterfølgende ses nedenfor:`,
                                    "title": "SQL Relationsdatabase - Databaser.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=a21a8fc1-c83c-417a-afcc-fd225d85e3bc",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `Ønsker du at få et større indblik i databasen og dataene i den, kan filen downloades her:`,
                                    "download": "https://zjgpqahpxlidtfxsxeah.supabase.co/storage/v1/object/sign/testing_1/dinero_case.db?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ0ZXN0aW5nXzEvZGluZXJvX2Nhc2UuZGIiLCJpYXQiOjE3MTc1OTk2NjcsImV4cCI6MTc0OTEzNTY2N30.GlnxfD5wwmmmpvhwznKFOUwIPgQ2WUntrhzpdVmo09Y&t=2024-06-05T15%3A01%3A07.896Z",
                                    "download_name": "dinero_case_database.db"
                                }
                            ]
                        }
                    ]
                },
                {
                    "element": "Sikkerhed og lovgivning ift. data",
                    "path": "/projekter/sikkerhed-og-lovgivning",
                    "value": "sikkerhed-og-lovgivning",
                    "data": [
                        {
                            "title": "Hvad var formålet?",
                            "text": `Formålet med forløbet om digital sikkerhed og adfærd var, at give os et større indblik i de aftryk vi sætter når vi færdes online, både de synlige, men også de usynlige. Vi arbejdede altså med emnet for at øge vores kompetencer inden for digital myndiggørelse.
                            
                            Forløbet dækker alt fra hvordan virksomheder indsamler data om os, både gennem data vi selv giver, men også data de indsamler gennem blandt andet cookies.
                            
                            Derudover var et fokusområde cybersikkerhed, og hvordan både virksomheder og privatpersoner kan beskytte sig mod trusler fra nettet. Det dækker alt fra firewalls til VPN-forbindelser og backups af data.
                            
                            Sidst men ikke mindst skulle vi have en større forståelse for de eksisterende love om datalovgivning, herunder GDPR. Det dækkede alt fra hvilke rettigheder man som privatperson i forhold til din data, men også hvordan virksomheder skal og må opbevare data.`,
                        },
                        {
                            "title": "Hvordan løste vi opgaven?",
                            "text": `For at få et større indblik i data og datalovgivningen, afprøvede vi blandt andet Facebooks funktion i forhold til at anmode om en fuld oversigt over alle dataene de opbevarer om en. En anden funktion vi afprøvede var at se virksomheders annonceinformationer, altså hvilke aktive og inaktive annoncer de har, samt mere uddybende information som hvem forskellige annoncer er målrettet imod.
                            
                            Det var her lidt skræmmende at se, hvor meget de egentligt ved om en, herunder hvilke ANDRE hjemmesider man har besøgt og hvad man har foretaget sig på Facebook.
                            
                            Derudover afprøvede vi https://haveibeenpwned.com for at få et indblik i om vores oplysninger har været lækket under et hackerangreb.
                            
                            Også wayback machine blev taget i brug for at få et indblik i hjemmesider for mange år tilbage, og de oplysninger der er umulige at fjerne fra internettet.
                            
                            Til at slutte af, prøvede vi at indtaste passwords i en simulator for at se, hvor lang til det vil tage en hacker at komme igennem.`,
                        },
                        {
                            "title": "Beskrivelse af løsning - med billeder",
                            "text": `Modulet indebar som nævnt ovenfor en masse mindre gennemgange og områder. Derfor har jeg udvalgt nogle af de hjemmesider vi besøgte og vist med billeder nedenfor:`,
                            "media": [
                                {
                                    "text": `Her ses et skærmbillede fra haveibeenpawned, hvor jeg kan se, at min email er blevet lækket i to forskellige hackerangreb.`,
                                    "title": "Haveibeenpawned - Sikkerhed.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=08316c0e-4e11-4125-aac2-c813c939c599",
                                    "width": "640",
                                    "height": "360"
                                },
                                {
                                    "text": `På billedet nedenfor anmodede vi om alle oplysninger Facebook opbevarer om os:`,
                                    "title": "Facebook Oplysninger - Sikkerhed.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=dc41b837-6444-46e2-a81e-5a76f46b9506",
                                    "width": "640",
                                    "height": "360"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "element": "Begreber",
            "path": "/begreber",
            "value": "begreber",
            "subpages": [
                {
                    "element": "Digitale Artefakter",
                    "path": "/begreber/digitale-artefakter",
                    "value": "digitale-artefakter",
                    "data": [
                        {
                            "title": "En kort definition af begrebet",
                            "text": "Et digitalt artefakt er en genstand eller enhed, som har en eller anden form for et digitalt aspekt.",
                        },
                        {
                            "title": "En uddybning af begrebet",
                            "text": `Det digitale artefakt er hvad der ligger til grund for de resterende emner og kapitler i erhvervsinformatik-faget. Alt fra design og prototyper til programmering, arkitekturen i det digitale artefakt og databaser er hvad det digitale artefakt er gjort af.
                            
                            Et digitalt artefakt er altså en ting, som bygger på elektronik og teknologi.`,
                        },
                        {
                            "title": "Hvorfor er begrebet relevant?",
                            "text": `Begrebet digitale artefakter er altså vigtigt at forstå, fordi det i første omgang er en af grundstenene i faget erhvervsinformatik - uden det vil de resterende emner i faget være mere splittet og mindre sammenhængende.`,
                        },
                        {
                            "title": "Hvordan kan virksomheder eller individet bruge begrebet?",
                            "text": `Ud over at være vigtigt for forståelsen af faget, er digitale artefakter også vigtige for især virksomheder. Det er de først og fremmest, fordi virksomheder i større og større grad er afhængig af digitale artefakter i deres arbejde, men også fordi mange virksomheder selv producerer og udvikler digitale artefakter.
                            
                            Det er altså vigtigt at virksomhederne forstår digitale artefakter og hvad der ligger bag, for at de kan udvikle det bedst mulige produkt til deres kunder. De er afhængige af teknologiforståelsen.`,
                        },
                    ]
                },
                {
                    "element": "Analyse Af Digitale Artefakter",
                    "path": "/begreber/analyse-af-digitale-artefakter",
                    "value": "analyse-af-digitale-artefakter",
                    "data": [
                        {
                            "title": "En kort definition af begrebet",
                            "text": "Man laver en analyse af et digitalt artefakt for at få en bredere forståelse for hvad det digitale artefakt i første omgang er og hvad det består af. Derudover dykker man ned i hvad formålet med det er, og hvordan brugerne benytter det - altså om de overhovedet benytter det på samme måde som det er tiltænkt. På baggrund af de områder laves der en konsekvensvurdering, hvor man undersøger hvilke positive eller negative konsekvenser det digitale artefakt har medført."
                        },
                        {
                            "title": "En uddybning af begrebet",
                            "text": "Begrebet dækker over en analysemodel med 4 underliggende kategorier:",
                            "underpunkt": [
                                {
                                    "title": "1. Teknologisk analyse",
                                    "text": [
                                        {
                                            "punkt": "Udseende - Form, farve, størrelse, altså det fysiske. Hjemmesider og apps har IKKE noget udseende"
                                        },
                                        {
                                            "punkt": "Funktionalitet - Hvad artefaktet kan og hvilke funktioner det har"
                                        },
                                        {
                                            "punkt": "Brugergrænseflade - Det der forbinder brugeren med teknologien, f.eks. skærmen"
                                        },
                                        {
                                            "punkt": "Input-teknologi - Det data brugeren sender til artefaktet"
                                        },
                                        {
                                            "punkt": "Output-teknologi - Det data eller output der sendes tilbage til brugeren gennem artefaktet"
                                        }
                                    ]
                                },
                                {
                                    "title": "2. Formåls analyse",
                                    "text": [
                                        {
                                            "punkt": "Ideen bag - Hvad er ideen bag artefaktet of hvorfor er det skabt?"
                                        },
                                        {
                                            "punkt": "Skaberen - Hvem står bag det digitale artefakt og hvilken betydning har det? er det godt eller skidt?"
                                        },
                                        {
                                            "punkt": "Målgruppen - Hvem er artefaktet lavet til?"
                                        },
                                        {
                                            "punkt": "Andre brugere - Giver det mening for andre end målgruppen at benytte artefaktet, hvordan og hvorfor?"
                                        }
                                    ]
                                },
                                {
                                    "title": "3. Brugsstudie",
                                    "text": [
                                        {
                                            "punkt": "Anvendelse - Hvor og hvordan anvendes artefaktet, og i hvilken sammenhæng?"
                                        },
                                        {
                                            "punkt": "Betydning - Kan artefaktet gøre et stykke arbejde lettere og bedre, eller sværere? og kan andre medarbejdere løse opgaven?"
                                        },
                                        {
                                            "punkt": "Kompetencer - Hvilke kompetencer kræver det at benytte artefaktet?"
                                        },
                                        {
                                            "punkt": "Fordele - Fordele ved at anvende artefaktet"
                                        },
                                        {
                                            "punkt": "Ulemper - Ulemper ved at anvende artefaktet"
                                        }
                                    ]
                                },
                                {
                                    "title": "4. Konsekvensvurdering",
                                    "text": [
                                        {
                                            "punkt": "Arbejdsgange - Hvordan ændrer artefaktet arbejdet for medarbejderen og for kunden? gør det arbejdet mere effektivt eller lettere?"
                                        },
                                        {
                                            "punkt": "Arbejdsmiljø - Ændrer artefaktet på etikken i virksomheden eller hvordan arbejdspladsen er?"
                                        },
                                        {
                                            "punkt": "Arbejdets organisering - Kan det påvirke måden hvorpå arbejdet fordeles og udføres? Skaber det behov for ekstra medarbejdere, eller gør det medarbejdere overflødige?"
                                        },
                                        {
                                            "punkt": "Effekter - Vil det påvirke virksomheden positivt eller negativt?"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "title": "Hvorfor er begrebet relevant?",
                            "text": "Begrebet er relevant ift. faget erhvervsinformatik, fordi det er en del af kompetencen “digital myndiggørelse”, som handler om teknologiforståelse og at forstå konsekvensen af at indføre digitale artefakter i virksomheden."
                        },
                        {
                            "title": "Hvordan kan virksomheder eller individet bruge begrebet?",
                            "text": "Betydningen for virksomheden hænger i stor grad sammen med digital myndiggørelse, altså at virksomhederne kan bruge analyse af digitale artefakter til at forstå, hvordan det vil påvirke deres virksomhed før artefaktet indføres. På baggrund af analysen kan de træffe bedre beslutninger for virksomheden."
                        },
                        {
                            "title": "Videoeksempel - Analyse af Canva",
                            "media": [
                                {
                                    "title": "ANALYSE AF CANVA.mp4",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=d26ec41b-2f21-4f62-ad18-12e0bfbae561&nav=%7B%22playbackOptions%22%3A%7B%22startTimeInSeconds%22%3A0%7D%7D&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create",
                                    "width": "640",
                                    "height": "360"
                                }
                            ]
                        }
                    ]
                },
                {
                    "element": "Interaktionsdesign",
                    "path": "/begreber/interaktionsdesign",
                    "value": "interaktionsdesign",
                    "data": [
                        {
                            "title": "En kort definition af begrebet",
                            "text": "Interaktionsdesign er hvordan vi, ved hjælp af teorier og regler, designer hjemmesider og andre produkter, så de opfylder deres formål.",
                        },
                        {
                            "title": "En uddybning af begrebet",
                            "text": "Interaktionsdesign er hvordan vi designer vores hjemmeside eller app - så designet støtter op om formålet med siden. Vil vi gerne sælge gennem siden? - Så skal siden designes så processen bliver så nem for brugeren som muligt. Begrebet dækker over:",
                            "underpunkt": [
                                {
                                    "title": "Designregler",
                                    "text": [
                                        {
                                            "punkt": "First things first - Vi placerer det vigtigste først - det brugeren skal vide står først og skal ikke gemmes væk. De vigtigste overskrifter først. De vigtigste ord først i sætningen. Den vigtigste sætning først i brødteksten. Kort sagt: Det vigtigste først. Altid."
                                        },
                                        {
                                            "punkt": "Keep it simple and straight (KISS) - Hold det simpelt. Alt det unødvendige skal væk"
                                        },
                                        {
                                            "punkt": "Gestaltlovene - Loven om nærhed - Er noget tæt på hinanden, så opfattes det som sammenhørende. Loven om lukkethed - Det der hegnes sammen har noget med hinanden at gøre. Loven om lighed - Vi forventer at ting der minder om hinanden er det samme. Loven om forbundethed - Binder vi noget sammen, så har det også noget tilfælles"
                                        },
                                        {
                                            "punkt": "Input-teknologi - Det data brugeren sender til artefaktet"
                                        }
                                    ]
                                },
                                {
                                    "title": "Fonte og farver",
                                    "text": [
                                        {
                                            "punkt": "Fonte og farver forbindes med brandet - hold samme tema hele vejen igennem"
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            "title": "Hvorfor er begrebet relevant?",
                            "text": "Begrebet er relevant at kende til, da det er teorien bag design og konstruktion af en successfuld hjemmeside eller anden platform. Med successfuld mener jeg, at formålet med siden bliver opfyldt.",
                        },
                        {
                            "title": "Hvordan kan virksomheder eller individet bruge begrebet?",
                            "text": "Virksomheder kan bruge teorierne i deres designprocess, for at optimere deres side til netop det formålet med den er. Hvis formålet er at konvertere flere leads, kan indholdet altså optimeres, så siden er brugervenlig på en sådan måde, at det konverterer flere leads.",
                        },
                        {
                            "title": "Eksempel - Brug af designregler ved Zalando",
                            "text": "Nedenfor ses en illustration over steder på Zalandos hjemmesiden, hvor der er gjort brug af designregler.",
                            "media": [
                                {
                                    "title": "DESIGNREGLER - ET EKSEMPEL.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=15b69ed2-8767-4645-9d5e-0a6b06863aff",
                                    "width": "617",
                                    "height": "360"
                                }
                            ]
                        }
                    ]
                },
                {
                    "element": "3-lags-arkitektur",
                    "path": "/begreber/3-lags-arkitektur",
                    "value": "3-lags-arkitektur",
                    "data": [
                        {
                            "title": "En kort definition af begrebet",
                            "text": "3-lags-arkitekturen er teorien bag digitale artefakter og hvordan de er opbygget. De digitale artefakter består af de tre lag: Præsentationslaget, Logiklaget og Datalaget.",
                        },
                        {
                            "title": "En uddybning af begrebet",
                            "text": `Dykker vi ned i de enkelte lag, er præsentationslaget kort sagt alt det synlige ved det digitale artefakt - det er brugergrænsefladen og altså alt brugeren kan se og interagere med.
                            
                            Derudover har vi logiklaget, som er logikken og koden der driver det digitale artefakt - det er logiklaget som ændrer præsentationslaget på baggrund af datalaget.
                            
                            Til sidst er der som sagt datalaget. Datalaget er også kendt som vores database, der opbevarer data samt søger og sender data til logiklaget.
                            
                            Alt i alt udgør de tre lag det digitale artefakt.`,
                        },
                        {
                            "title": "Hvorfor er begrebet relevant?",
                            "text": `3-lags-arkitektur er relevant ift. erhvervsinformatik faget, fordi det er essentielt for at opnå kompentencer inden for “Teknologisk handleevner og computationel tankegang”. Altså at forstå tankegangen bag teknologi og hvordan det opbygges.`,
                        },
                        {
                            "title": "Hvordan kan virksomheder eller individet bruge begrebet?",
                            "text": `Begrebet er mindre relevant for virksomheder der ikke selv udvikler digitale artefakter eller software, men er til gengæld meget relevant for de der gør.

                            For at virksomhederne kan bygge deres produkt, altså det digitale artefakt, f.eks. et stykke software, en hjemmeside eller noget helt tredje, skal de forstå strukturen og opbygningen af det.`,
                        },
                    ]
                },
                {
                    "element": "Programmering",
                    "path": "/begreber/programmering",
                    "value": "programmering",
                    "data": [
                        {
                            "title": "En kort definition af begrebet",
                            "text": "Digitale artefakter, som mere eller mindre hele faget bygger på, er dybt afhængige af programmering. Helt konkret er programmeringen hele logikken bag, altså logiklaget.",
                        },
                        {
                            "title": "En uddybning af begrebet",
                            "text": `Programmering er meget overordnet måden at få computere til at udføre handlinger.
                            
                            Under det overordnede begreb “programmering” ligger altså mange begreber, som er essentielle for at kunne beskrive og forklare kode. Herunder er en liste over relevante begreber og en kort beskrivelse:`,
                            "underpunkt": [
                                {
                                    "title": "Funktioner",
                                    "text": [
                                        {
                                            "punkt": "En funktion er til for at løse en konkret opgave. Det kan være at funktionens opgave er at lægge to tal sammen. Funktionens argumenter, hvilket vil sige de data der indgår i funktionen, er altså to tal. Når programmet så kalder funktionen med tallene 4 og 3 som argumenter, vil funktionen returnere tallet 7."
                                        },
                                    ]
                                },
                                {
                                    "title": "Variabel",
                                    "text": [
                                        {
                                            "punkt": "En variabel er, som det ligger i navnet, en pladsholder for en værdi. Det kan være, at du har behov for at opbevare brugerens brugernavn, fordi du skal sende det tilbage til brugeren om 5 sekunder. Her kan en variabel bruges."
                                        },
                                    ]
                                },
                                {
                                    "title": "Sekvenser",
                                    "text": [
                                        {
                                            "punkt": "Sekvensen er den rækkefølge vores kode udføres i, altså strukturen af koden. Det vil naturligt være fra toppen til bunden."
                                        },
                                    ]
                                },
                                {
                                    "title": "Løkker",
                                    "text": [
                                        {
                                            "punkt": "En løkke er kort sagt en gentagelse. Med en løkke kan man altså gentage den samme handling flere gange. Har du for eksempel en liste med 34 brugere, som du skal sende en mail til, kan du bruge en løkke til at gentage handlingen, altså at sende mailen, for hver bruger der indgår i listen."
                                        },
                                    ]
                                },
                                {
                                    "title": "Forgreninger",
                                    "text": [
                                        {
                                            "punkt": "Når der er flere forskellige muligheder bruges en forgrening. Kigger vi igen på vores eksempel med funktionen, der lagde tal sammen, kan forgreningen her finde sted ved værdien af outputtet. Er værdien af outputtet over 0, altså positivt, kan vi få programmet til at fortælle brugere, at værdien er positiv, hvor vi, hvis den er negativ, kan fortælle det til brugeren. Forgreninger kendes også som “IF / ELSE statements”."
                                        },
                                    ]
                                },
                            ]
                        },
                        {
                            "title": "Hvorfor er begrebet relevant?",
                            "text": `For faget erhvervsinformatik er programmering og alt det indebærer relevant ift. kompetencerne “Erhvervsrettet digital udvikling” og “Teknologisk handleevner og computationel tankegang”.
                            
                            Programmering er altså det at have en viden om hvad der sker hver gang du trykker på en knap. Det bygger ind i den sidstnævnte kompetence i forhold til computationel tankegang, altså at kunne omdanne et problem til en løsning ved hjælp af meget konkrete skridt.`,
                        },
                        {
                            "title": "Hvordan kan virksomheder eller individet bruge begrebet?",
                            "text": `Ligesom mange af de andre områder i faget, er relevansen for programmering meget afhængig af din fremtidige jobfunktion. Ønsker virksomheden for eksempel at udarbejde et nyt produkt, i form af et digitalt artefakt, skal de først og fremmest have konceptet på plads. Det kan de gøre ved at udarbejde en prototype. For at deres prototype og i sidste ende produkt kan blive funktionsdygtigt, kræver det altså at medarbejderne forstår hvordan de digitale artefakter er opbygget, og hvad der indgår i de forskellige lag - i dette eksempel logiklaget.
                            
                            Skal man arbejde med kundeservice ved en softwarevirksomhed, vil det være en stor hjælp at have bare en smule viden om programmering og det der ligger bag, for at kunne løse eventuelle problemer med produktet eller formidle produktets egenskaber til kunderne.`,
                        },
                    ]
                },
                {
                    "element": "Databaser",
                    "path": "/begreber/databaser",
                    "value": "databaser",
                    "data": [
                        {
                            "title": "En kort definition af begrebet",
                            "text": "Databaser bruges kort sagt til at opbevare, finde, bruge og redigere i data.",
                        },
                        {
                            "title": "En uddybning af begrebet",
                            "text": `Databaser indgår i stor grad i alt hvad vi bruger i dag- både som virksomhed, men også som privatpersoner. Alt fra webshops til fysiske butikker, apps, osv. er dybt afhængige af data, som opbevares i databaser.
                            
                            Under det overordnede begreb ‘databaser’ ligger mange mindre begreber, som findes nedenfor, med en kort tilhørende beskrivelse.`,
                            "underpunkt": [
                                {
                                    "title": "Flade Databaser",
                                    "text": [
                                        {
                                            "punkt": "En flad database er den mest simple form for en database. Her indtastes alle dataene i én tabel. En flad database kan altså bruges hvis man let skal have et overblik over få informationer, men fungerer mindre godt til større og mere komplekse datasæt."
                                        },
                                    ]
                                },
                                {
                                    "title": "Relationsdatabaser",
                                    "text": [
                                        {
                                            "punkt": "En relationsdatabase bygger, som det ligger i navnet, på relationer. Her indgår flere tabeller, hver især med forskellige data, som kan kobles sammen ved brug af en primær-nøgle. Relationsdatabaser er mere komplekse end flade databaser, men hvis der er tale om større mængder af data, kan dataene lettere opbevares og behandles. Ved at benytte relationsdatabaser kan vi undgå redundans og derved inkonsistens."
                                        },
                                    ]
                                },
                                {
                                    "title": "Tabel",
                                    "text": [
                                        {
                                            "punkt": "En tabel samler oplysninger der har noget med hinanden at gøre. Det kan være en kunde-tabel, der indeholder alle kundeoplysninger som kundenr, navn, addresse, mail og telefonnummer. Tabellen består af kolonner, og rækker også kendt som poster."
                                        },
                                    ]
                                },
                                {
                                    "title": "Post",
                                    "text": [
                                        {
                                            "punkt": "Posten i en tabel udgør en række af data, altså sammenhængende data. Tager vi igen udgangspunkt i eksemplet med kundetabellen, vil en post altså bestå af alle dataene om en enkelt kunde. "
                                        },
                                    ]
                                },
                                {
                                    "title": "Felt",
                                    "text": [
                                        {
                                            "punkt": "Et felt er en konkret oplysning i posten. I kunde-tabellen kunne det være telefonnummeret."
                                        },
                                    ]
                                },
                                {
                                    "title": "Primær nøgle",
                                    "text": [
                                        {
                                            "punkt": "Den primære nøgle er en kolonne i tabellen. Nøglen skal være unik, da man bruger den til at identificere den enkelte post i tabellen."
                                        },
                                    ]
                                },
                                {
                                    "title": "Redundans",
                                    "text": [
                                        {
                                            "punkt": `Redundans er kort sagt gentagne data. Det er altså en form for dobbeltregistrering af data, som ikke er nødvendig. Det vil sige, at der for det første bruges mere lagringsplads i databasen, men også at der kan opstå fejl i registreringen af dataene, altså inkonsistens.
                                            Et eksempel på redundans kan være hvis både kundens alder og fødselsdagsdato er gemt i den flade database. Her kan kundens alder også udledes af deres fødselsdagsdato, hvorfor registreringen af alderen er unødvendig. Det kan altså skabe inkonsistens hvis den registrerede fødselsdagsdato og alder ikke stemmer overens.`
                                        },
                                    ]
                                },
                                {
                                    "title": "Inkonsistens",
                                    "text": [
                                        {
                                            "punkt": "Inkonsistens er, som nævnt ovenfor, en fejl i dataene, altså hvor dataene ikke stemmer overens. Der er en mangel på sammenhæng, hvor et eksempel kan være, at Vejle nogle steder er registeret under postnummeret 7100, mens det andre steder er 7150."
                                        },
                                    ]
                                },
                                {
                                    "title": "E/R-diagram",
                                    "text": [
                                        {
                                            "punkt": "Et E/R-diagram er en illustration over tabellerne i relationsdatabasen og deres relation, altså sammenhængen mellem tabellerne. Virksomheder bruger altså E/R diagrammer til at planlægge relationsdatabaser, så man er sikker på at databasen hænger sammen."
                                        },
                                    ]
                                },
                            ]
                        },
                        {
                            "title": "Hvorfor er begrebet relevant?",
                            "text": `Databaser og alt det indebærer er relevant at forstå, fordi det fylder et stort område inden for kompetencen: “Teknologisk handleevner og computationel tankegang”. Det at have en viden om databaser og deres struktur er altså først og fremmest en del af pensum og de kompentencer vi arbejder med, men det er også essentielt for vores forståelse af data og behandlingen af disse.`,
                        },
                        {
                            "title": "Hvordan kan virksomheder eller individet bruge begrebet?",
                            "text": `Nogle virksomheder har hele afdelinger til at opbygge og vedligeholde databaser. I en sådan situation kan forståelsen for databaser altså ikke bidrage meget. Et pragteksemplar vil dog være i for eksempel en detailbutik.
                            
                            Lad os antage, at du er elev i en mindre detailbutik i Vejle midtby. Din chef har lagt mærke til at flere og flere er begyndt at handle- og finde produkter online, men ved ikke helt hvordan hun skal komme i gang.
                            
                            Du foreslår at første step kan være at lave et nyhedsbrev. Ved at lave et nyhedsbrev kan din chef informere tidligere og eksisterende kunder om nye produkter og tilbud i butikken.                       
                            
                            Men i har brug for en liste af kunder at sende nyhedsbrevet ud til. Du tilbyder derfor at lave en simpel relationsdatabase, hvor du opbevarer fremtidige kunders kontaktinformation og ordrer (hvis de giver dig lov selvfølgelig).                            
                            
                            Du har altså brugt din viden om databaser til at hjælpe din arbejdsplads med at få flere besøgende kunder og ordrer.`,
                        },
                        {
                            "title": "Eksempel på E/R diagram og dele af en relationsdatabase",
                            "text": ``,
                            "media": [
                                {
                                    "text": "For at forstå databaser og hvad der ligger bag bare en smule bedre, har jeg illustreret delene i en tabel nedenfor. En relationsdatabase er altså opbygget af flere tabeller, der kan kobles sammen på kryds og tværs ved hjælp af primærnøglen.",
                                    "title": "Tabellens opbygning - Illustration.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=8a25c803-2fdd-4bc3-9909-85e11725b09d",
                                    "width": "640",
                                    "height": "350"
                                },
                                {
                                    "text": "Her ses også et eksempl på et E/R diagram:",
                                    "title": "E/R Diagram - Eksempel.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=c0655f72-c4a9-4eef-8ff4-03bc578324c2",
                                    "width": "640",
                                    "height": "303"
                                }
                            ]
                        },
                    ]
                },
            ]
        },
        {
            "element": "Værktøjer",
            "path": "/værktøjer",
            "value": "værktøjer",
            "subpages": [
                {
                    "element": "Canva",
                    "path": "/værktøjer/canva",
                    "value": "canva",
                    "data": [
                        {
                            "title": "Teknologianalyse",
                            "text": `Canva er et softwareprogram og eksisterer altså kon digitalt. Derfor har det ikke noget fysisk udseende. Det har dog stadig en brugergrænseflade, hvorigennem vi kommunikerer med artefaktet. Brugergrænsefladen består af de forskellige sektioner på deres hjemmeside, herunder en navigationsbar, søgefelt, banner, samt de forskellige knapper og elementer der indgår i disse.
                            
                            De primære funktioner i Canva indebærer registrering af bruger. Brugeren kan oprette nye projekter, bruge udgiver skabeloner og sammensætte billeder og illustrationer af de forskellige elementer på platformen.
                            
                            Det digitale artefakt modtager forskelligt input gennem vores computer og adfærd på platformen. Dataene indebærer for eksempel placeringen af vores mus på side og hvilken bruger der interagerer med siden. Derudover registreres det når der trykkes på forskellige elementer.
                            
                            Som brugere kan vi interagere og kommunikere med det digitale artefakt gennem vores tastatur og mus.
                            
                            På baggrund af det input det digitale artefakt modtager fra os, returnerer det et output. Outputtet er forskelligt alt efter hvad vi foretager os, men det indebærer, at elementerne og indholdet ændres baseret på vores input.`,
                        },
                        {
                            "title": "Formåls Analyse",
                            "text": `For individet, altså brugeren, er formålet med det digitale artefakt at gøre design nemt og ligetil. Det gøres blandt andet gennem forudarbejdede skabeloner fra andre brugere.
                            
                            For Canva, altså virksomheden bag det digitale artefakt, er formålet at tjene penge. Hvis de ikke tjener penge på det, vil deres virksomhed ikke kunne løbe rundt og derfor ikke eksistete.
                            
                            Kort sagt har virksomheden bag platformen skabt det digitale artefakt for at hjælpe folk, der ikke har kompetencerne til at udarbejde grafisk materiale, med at lave noget udmærket materiale. Brugeren kan benytte platformen gratis, men ønsker de at få fuld adgang, er det mod betaling.
                            
                            I den forbindelse kigger vi på målgruppen for det digitale artefakt. Set fra et virksomheds-perspektiv er målgruppen den eller de personer der har et ansvar inden for design eller har brug for grafiske elementer i deres hverdag og arbejde. Målgruppen kan altså både være virksomheder, men også klubber, foreninger, osv. der ikke har budgettet til at hyre en grafisk designer.
                            
                            Grunden til at målgruppen benytter artefaktet er at få hjælp eller inspiration til det grafiske arbejde gennem skabeloner, værktøjer og ved at oprette projekter, hvor flere mennesker kan samarbejde. Det er altså kort sagt at tage brugerens evner ift. grafisk design fra 0% til 50%.
                            
                            Derudover kan blandt andet elever også bruge det til skoleopgaver eller projekter.`,
                        },
                        {
                            "title": "Brugsstudie",
                            "text": `Da det digitale artefakt har et konkret formål, men kan bruges af mange, anvendes det helt naturligt mange forskellige steder. Det er alt fra erhvervsliver til foreninger, der skal udarbejde koncepter eller andre visuelle produkter. Det kan også bruges af privatpersoner til for eksempel at lave invitationer til fester.
                            
                            Kigger vi ind i erhvervsområdet, kan medarbejderne i virksomheder bruge Canva til at udarbejde online-annoncer, salgstilbud eller fysiske plakater til butikkerne.
                            
                            Som udgangspunkt kræver det at benytte det digitale artefakt en grundlæggende forståelse for platformen og funktionerne deri. Derudover skal brugeren have en computer og adgang til internettet.
                            
                            Det at Canva kan bruges af så mange, har også haft en indflydelse på hvem der udfører eller kan udføre det grafiske arbejde i en virksomhed. Hvor det førhen var medarbejdere med en viden inden for design og professionelle designprogrammer, herunder adobe illustrator eller photoshop, kan mere eller mindre alle medarbejdere i virksomheden udføre arbejdet. Det et måske ikke på samme niveau, men arbejdet kan udføres af mange flere end før.
                            
                            Virksomheder kan altså primært bruge det digitale artefakt som en erstatning for en grafisk designer, men også i forhold til at effektivisere arbejdet, hvor den enkelte medarbejder selv kan opstille et design hvis der er brug for det. Derved skal de altså ikke sende en forespørgsel til design-afdelingen og vente på at de sender produktet retur.
                            
                            En ulempe ved at benytte Canva er dog at virksomhedens designs og designidentitet bliver mindre originale, fordi medarbejderne benytter offentlige skabeloner, som også benyttes af andre individer og virksomheder.`,
                        },
                        {
                            "title": "Konsekvensvurdering",
                            "text": `Canva kan effektivisere det grafiske arbejde - og derved spare penge og ressourcer. Vi kan også skabe bedre kunderelationer gennem professionalitet og kommunikation, hvor medarbejderne kan lave flotte visuelle repræsentationer.
                            
                            Konsekvensen for medarbejder kan i sidste ende være både positiv og negativ. Er du den generelle medarbejder kan det medføre en smule mere arbejde, hvis du nu selv skal udarbejde grafisk materiale. Er du derimod en grafisk designer kan det medføre mindre arbejde, eller måske helt erstatte din arbejdsfunktion - altså en negativ konsekvens.
                            
                            Vælger virksomheden at deres medarbejdere skal benytte Canva, kan det altså kræve, at medarbejderne skal på kursus for at lære programmet at kende. Derudover kan det også ændre strukturen ift. hvilke opgaver der udføres af hvem. Arbejdsgangene bliver altså ændret, og det kan kræve en investering fra virksomhedens side.
                            
                            På kort og langt sigt kommer konsekvenserne meget an på den enkelte virksomhed - hvis der er tale om en lille virksomhed med en eller to ansatte, kan det hjælpe med at forbedre og effektivisere arbejdet i virksomheden - samt få virksomheden til at fremstå mere professionel.
                            
                            Det kan dog også, som nævnt ovenfor, give flere arbejdsopgaver til den enkelte medarbejder.`,
                        },
                        {
                            "title": "Kort gennemgang af Canva som et digitalt artefakt",
                            "text": ``,
                            "media": [
                                {
                                    "text": "",
                                    "title": "ANALYSE AF CANVA.mp4",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=d26ec41b-2f21-4f62-ad18-12e0bfbae561&nav=%7B%22playbackOptions%22%3A%7B%22startTimeInSeconds%22%3A0%7D%7D&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create",
                                    "width": "640",
                                    "height": "360"
                                }
                            ]
                        },
                    ]
                },
                {
                    "element": "Chat-GPT",
                    "path": "/værktøjer/chat-gpt",
                    "value": "chat-gpt",
                    "data": [
                        {
                            "text": `Chat-GPT er et digitalt artefakt. Derfor beskriver jeg værktøjet på baggrund af analysen af digitale artefakter. Den samlede analyse består af underpunkter, som er afhængige af hinanden. Underpunkterne inkluderer en teknologianalyse som beskriver artefaktet helt konkret, en formålsanalyse, et brugsstudie og en konsekvensvurdering.`
                        },
                        {
                            "title": "Teknologianalyse",
                            "text": `Chat-GPT er helt konkret det der også kaldes en LLM, eller ”large-language-model”. Altså er dets grundlæggende funktion, at brugeren kan stille et spørgsmål og modtage et svar.
                            
                            Da Chat-GPT er software, har det ikke noget fysisk udseende. Dog er den, som alle andre digitale artefakter, opbygget efter 3-lags-arkitekturen. 
                            
                            Brugergrænsefladen, også kendt som præsentationslaget, er alt vi kan se og interagere med. Præsentationslaget består af de to overordnede sektioner: Navigation og Chatområde.
                            
                            Logiklaget kan vi ikke se, men det vi med sikkerhed kan sige er, at nogle såkaldte “event listeners” venter på at vi foretager os noget, hvorefter de udfører en opgave. Det kan være at vi trykker på en af de ældre chats i navigations-området. Det registreres af logiklaget, hvorefter den sender en anmodning til datalaget om at hente alle data fra den givne chat-sektion frem.
                            
                            Når logiklaget modtager dataene fra datalaget, opdateres indholdet på siden med de givne data.
                            
                            For at kommunikere med det digitale artefakt, sender brugeren manuelt data gennem input-feltet i bunden, hvorefter denne modtager feedback i chat-området. Andet data om brugeren der kommunikerer med artefaktet bliver også automatisk lagret i databasen på datalaget.`,
                        },
                        {
                            "title": "Formåls Analyse",
                            "text": `Formålet med Chat-GPT er at brugeren kan kommunikere gennem billeder, tekst, video eller lyd og modtage et svar i det format denne ønsker. Det digitale artefakt kan altså bruges til at udarbejde tekster, føre en samtale eller generere billeder, video og lyd.`,
                        },
                        {
                            "title": "Brugsstudie",
                            "text": `Det at Chat-GPT har et forholdsvist bredt formål, resulterer også i, at der er mange forskellige brugere, som bruger det digitale artefakt på mange forskellige måder. Det er alt fra at brainstorme nye ideer og få inspiration, til at udarbejde indhold til sociale medier i form af billeder og video. Nogle skoleelever bruger også Chat-GPT til at lave afleveringer eller få hjælp til andre skoleopgaver.
                            
                            For at bruge det digitale artefakt, kræver det som udgangspunkt at brugeren har en udmærket viden i forhold til teknologi. Derudover skal de have adgang til en enhed, som kan tilgå platformen, samt nogle basale kommunikationsevner for at kommunikere med det digitale artefakt og modtage brugbar respons.`,
                        },
                        {
                            "title": "Konsekvensvurdering",
                            "text": `Chat-GPT har haft meget omtale i bl.a. medierne, hvilket skyldes de både positive og negative konsekvenser artefaktet har medført. For eksempel kan flere arbejdsopgaver og roller i virksomhederne effektiviseres, hvilket er positivt for virksomheden. Bruges det digitale artefakt rigtigt, kan brugeren lære utrolig meget på kort tid, fordi det er som at have en privat underviser lige ved hånden. Dog er en af de helt store negativer, at mange skoleelever bruger programmet til at slippe lettest uden om arbejdet og snyde. Derfor lærer de ikke det de egentligt skal, hvilket på både kort og langt sigt kan have store konsekvenser.`,
                        },
                        {
                            "title": "",
                            "text": ``,
                            "media": [
                                {
                                    "text": "",
                                    "title": "Chat-GPT Brugergrænseflade.png",
                                    "url": "https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=6e4edf9c-d704-4a4d-8e00-bc155f80aa1e",
                                    "width": "640",
                                    "height": "360"
                                }
                            ]
                        },
                    ]
                },
                {
                    "element": "Dinero",
                    "path": "/værktøjer/dinero",
                    "value": "dinero",
                    "data": [
                        {
                            "text": `Dinero er et simplificeret ERP-system, hvor de primære funktioner ligger i udarbejdelse af fakturaer og salgstilbud samt registrering af diverse vare- og serviceindkøb. Derudover kan virksomheder opbygge en kontaktdatabase, som indeholder både kunder og leverandører.

                            Ud over de primære funktioner eksisterer også funktioner, der giver brugeren indblik i diverse nøgletal som omsætning, dækningsgrad, overskudsgrad, resultatopgørelse og balance.
                            
                            Her er iværksættere samt mindre- eller mellemstore virksomheder det primære kundesegment, hvor formålet med systemet er at simplificere især regnskab og bogføring. Ved at strukturere og effektivisere processen under databehandling er målet, at brugerne, hvilket vil sige virksomhederne, kan fokusere på det de gør bedst i stedet for at kæmpe med ting som kredit, moms, regler i forhold til fradrag, og så videre.
                            
                            Det skal også nævnes, at Dinero kan bruges af elever og studerende i forbindelse med undervisningen, hvor systemet derigennem tjener det formål at give eleverne et indblik i ERP- systemer og hvordan de benyttes.
                            
                            I forbindelse med Dinero-workshop-dagen udførte jeg flere opgaver i programmet og opnåede derved en vis grad af kendskab til det. Opgaverne indebar registrering af kunder, ordrer, indkøb, produktdatabase og lagerbeholdning, som først udførtes manuelt, hvorefter samme opgaver udførtes i Dinero.
                            
                            Efter få minutter gik det op for mig, at Dinero fjerner byrden ved dobbeltarbejde, hvor de samme kundeoplysninger og ordrenumre skal registreres flere forskellige steder, gentagne gange. Systemet skaber et langt bedre overblik over indtægter og udgifter, men lader dig også udarbejde og sende fakturaer direkte fra platformen.
                            Min oplevelse af Dinero var helt igennem positiv, sammenlignet med at udføre samme opgaver manuelt, i et Excel-ark.
                            
                            For at opsummere, så kan Dinero bidrage til en effektivisering af arbejdet samt gøre udførslen af opgaverne lettere for den enkelte medarbejder. For den ansatte kan det betyde, at arbejdsopgaven der ligger i håndtering og registrering af data bliver mindre frustrerende og mere overskuelig.
                            
                            Programmet bidrager også til et øget samarbejde, hvor flere medarbejdere kan arbejde med de samme data, på samme tid - Det er altså lettere at sætte nye ansatte ind i programmet, da det er et simpelt system, hvor medarbejderen ikke har behov for at kende sammensætningen af systemet på samme måde som et rodet Excel-ark lavet af en fjerde eller femte medarbejder.
                            
                            På kort sigt kan integreringen af Dinero i virksomheden medføre ekstraarbejde og opgaver da alle eksisterende kunder, leverandører, varer, og andet data skal føres ind i systemet. Med tiden vil det dog give mening for virksomheden, da programmet kan effektivisere deres arbejde.`
                        },
                    ]
                },
            ]
        },
        {
            "element": "Oversigt",
            "path": "/oversigt",
            "value": "oversigt",
        }
    ]

}

export const begrebData = {
    "begreber": [
        {
            
        }
    ]
}

export const projektData = {
    "projekter": [
        {
            "projektTitle": "ERP-Systemer - Dinero",
            "projektFormål": `Indsæt tekst her`,
            "projektOurSolution": "Indsæt tekst her",
            "projektSolutionDescription": "Indsæt tekst her",
        },
        {
            "projektTitle": "Programmering",
            "projektFormål": `Under programmeringsworkshoppen skulle vi, på vegne af sport24, udarbejde vores egen sundhedsapp. Sundhedsappen skulle først og fremmest have en BMI-måler eller beregner, som brugeren kan benytte.
            Derudover indsamlede vi data om klassens størrelser ift. højde, vægt og tøjstørrelser, som derefter skulle analyseres og bruges til at udarbejde personliggjorte reklamer i appen.
            Sidst men ikke mindst skulle vi selv ideudvikle og komme op med ideer til funktioner der kan tilføjes til appen, fastholde brugerne, og derigennem bidrage til sport24s forretning.
            
            Formålet med hele projektet var først og fremmest at få en større indsigt i programmering og hvordan værktøjer som code.org’s AppLab kan bruges til at udarbejde prototyper. Derudover kunne vi afprøve flere af teorierne omkring både designprocesser som den iterative designproces, Interaktionsdesign, herunder designregler ift. brugervenlighed og netværksarkitektur, som i forbindelse med sundsheds-appen er 3-lags-arkitektur.
            
            Data var ikke det største omdrejningspunkt for projektet, men vi skulle stadig stifte bekendskab med det, da næste projekt dykker ned i det.`,
            "projektOurSolution": "For at løse opgaven udarbejde vi, på baggrund af vores mockup og kravsspecifikation, en prototype i AppLab, som havde fokus på brugervenlighed, hvor vi gennem designregler som first-things-first, loven om lighed og loven om nærhed. Derudover brugte vi fonte og farver i et forsøgte på at holde sport24s visuelle identitet. ",
            "projektSolutionDescription": `Vores endelige løsning indeholder tre sider. En forside, bmi-måler og daily-challenge.
            
            Forsiden ses på billedet nedenfor, og består af sektionerne: Header, Reklame og Navigation. 
            Her får brugerens præsenteret virksomheden bag appen, en reklame, der tilpasses på baggrund af brugerens oplysninger, og en navigationssektion, som giver brugeren adgang til de primære funktioner i appen.
            
            Vores BMI-måler består af to input-bokse, hvor brugeren kan indtaste deres oplysninger og derefter får deres BMI-værdi og kategori præsenteret. På baggrund af resultatet tilpasses reklamen på forsiden, så den passer til brugerens størrelse.
            
            `,
        }
    ]
}