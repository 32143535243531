import React from "react";
import MediaQuery from 'react-responsive';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import { Navigation } from './components/Navigation';
//import { Hero } from './components/Hero';
import { Footer } from './components/Footer';
import { headerData } from './data/pageData';

import { HomePage, HomePageMobile } from './pages/Home';
import { NoPage, NoPageMobile } from './pages/NoPage';
import { Artikler, ArtiklerMobile } from './pages/Artikler'
import { Begreber, BegreberMobile } from './pages/Begreber'
import { Kompetenceområder, KompetenceområderMobile } from './pages/Kompetenceområder'
import { Projekter, ProjekterMobile } from './pages/Projekter'
import { Værktøjer, VærktøjerMobile } from './pages/Værktøjer'
import { OversigtPage } from './pages/Oversigt'
import './App.css';

function generateRoutes(data) {
    return data.elements.flatMap(element => [
        <Route key={element.path} path={element.path} element={<HomePage />} />,
        ...(element.subpages ? element.subpages.map(subpage => (
            <Route 
                key={subpage.path} 
                path={subpage.path} 
                element={
                    <div className='app'>
                        <div className='top'>
                            <Navigation data={headerData}/>
                            <h1 className="page_headline">{subpage?.element}</h1>
                            <div className="udvidet_information">
                                {subpage?.data && subpage.data.map(section => (
                                    <React.Fragment key={section?.title}>
                                        <h2 className="begreb_information">{section?.title}</h2>
                                        <p className="indepth_information" style={{ whiteSpace: 'pre-line' }}>
                                            {section?.text}
                                            {section.underpunkt && (
                                                <div className="underpunkt">
                                                    {section.underpunkt.map(underpunkt => (
                                                        <React.Fragment key={underpunkt?.title}>
                                                            <span className="bold">{underpunkt?.title}</span>
                                                            <div className="move_in">
                                                                {Array.isArray(underpunkt?.text) && underpunkt.text.map(points => (
                                                                    <li>{points?.punkt}</li>
                                                                ))}
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            )}
                                        </p>
                                        {section?.media && section.media.map(url => (
                                            <React.Fragment>
                                                <div className="begreb_video_parent">
                                                    <div className="begreb_video">
                                                        <p className="indepth_information" style={{ whiteSpace: 'pre-line' }}>
                                                            {url.text}
                                                        </p>
                                                        { url.link &&
                                                            <a href={url.link}>
                                                            {url.link}
                                                            </a>
                                                        }
                                                        { url.download &&
                                                            <a download={url.download_name} href={url.download}>
                                                                Download
                                                            </a>
                                                        }
                                                        { url.url &&
                                                            <iframe 
                                                            src={url.url} 
                                                            width={url.width} 
                                                            height={url.height}
                                                            frameborder="0" 
                                                            scrolling="no" 
                                                            allowfullscreen="true" 
                                                            draggable="false"
                                                            title={url.title}
                                                            className="iframe">
                                                            </iframe>
                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <Footer />
                        <Analytics />
                    </div>
                } 
            />
        )) : [])
    ]);
}



function DefaultScreen() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route index element={<HomePage />} />
                    <Route path='forside' element={<HomePage />} />
                    <Route path='kompetenceområder' element={<Kompetenceområder />} />
                    <Route path='projekter' element={<Projekter />} />
                    <Route path='artikler' element={<Artikler />} />
                    <Route path='begreber' element={<Begreber />} />
                    <Route path='værktøjer' element={<Værktøjer />} />
                    <Route path='oversigt' element={<OversigtPage />} />
                    {generateRoutes(headerData)}
                    <Route path="*" element={<NoPage />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

function MobileScreen() {
    return (
        <div>
            <BrowserRouter>
            <Routes>
                    <Route index element={<HomePageMobile />} />
                    <Route path='forside' element={<HomePageMobile />} />
                    <Route path='kompetenceområder' element={<KompetenceområderMobile />} />
                    <Route path='projekter' element={<ProjekterMobile />} />
                    <Route path='artikler' element={<ArtiklerMobile />} />
                    <Route path='begreber' element={<BegreberMobile />} />
                    <Route path='værktøjer' element={<VærktøjerMobile />} />
                    <Route path="*" element={<NoPageMobile />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

const App = () => {
    return (
        <div className='app'>

            <MediaQuery minWidth={768}>
                <DefaultScreen />
            </MediaQuery>

            <MediaQuery maxWidth={767}>
                <MobileScreen />
            </MediaQuery>

        </div>
    );
}

export default App;