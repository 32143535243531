import { Link, NavLink } from 'react-router-dom';
import { ShowNavigation } from '../scripts/showNavigation'
//import { CurrentPage } from '../scripts/currentPage'

function makeTextFit(text) {
    if (text.length <= 18) {
        return text;
    }
    return text.substring(0, 18) + '...';
}

function NavigationLinkGenerator({ data }) {
    console.log(data)
    const elements = data.elements
    return (
        <>
            {elements.map(element => (
                <li key={element.value} id={element.value}> 
                    <NavLink 
                        to={element.path} 
                        className="navigation_text base_text tab"
                    >
                        {element.element}
                    </NavLink>
                    {element.subpages && element.subpages.length > 0 && (
                        <div className='subpage-container'>
                            <ul>
                                {element.subpages.map(subpage => (
                                    <li key={subpage.value} id={subpage.value}>
                                        <NavLink 
                                            to={subpage.path} 
                                            className="subpage_navigation_text base_text tab"
                                        >
                                            {makeTextFit(subpage.element)}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </li>
            ))}
        </>
    );
}

function NavigationLinkGeneratorMobile({ data }) {
    console.log(data)
    const elements = data.elements
    return (
        <>
            {elements.map(element => (
                    <li id={element.value}> 
                    <NavLink 
                        to={element.path} 
                        className="navigation_text_mobile base_text tab"
                        //onClick={CurrentPage}
                        >
                            {element.element}
                    </NavLink>
                </li>
            ))}
        </>
    );
}
 
export function Navigation({ data }) {
    return (
        <div className='navigation_menu'>
            <div className='navigation'>
            <div className='navigation_left' id='navigationBar'>
                <NavLink 
                    to="/" 
                    className="base_text tab"
                    //onClick={CurrentPage}
                    >
                        Forside
                </NavLink>
            </div> 
            <ul className='navigation_right' id='navigationBar'>
                <NavigationLinkGenerator data={data} />
            </ul>
        </div>
            <hr className='navigation_divider' />
        </div>
    )
}

export function NavigationMobile({ data }) {
    return (
        <div className='navigation_menu'>
            <div className='navigation'>
            <div className='navigation_left' id='navigationBar'>
                <NavLink 
                    to="/" 
                    className="base_text tab"
                    //onClick={CurrentPage}
                    >
                        Forside
                </NavLink>
            </div> 
            <div className='navigation_right'>
                <img 
                    className='menu_icon' 
                    id='menuIcon'
                    onClick={ShowNavigation} 
                    src="https://media.discordapp.net/attachments/1185256400906104923/1201478135372320768/Menu_Ikon2.png" 
                    alt="menu_icon"/>
            </div>
        </div>
            <div className='overlay' id='navigationOverlay'>
                <a className='close_icon' onClick={ShowNavigation} href='#'>&times;</a>
                <ul className='navigation_mobile'>
                    <NavigationLinkGeneratorMobile data={data} />
                    <hr className='mobile_navigation_divider'/>
                </ul>
            </div>
            <hr className='navigation_divider' />
        </div>
    )
}