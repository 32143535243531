import React from 'react';
import { Analytics } from '@vercel/analytics/react';
import { headerData } from '../data/pageData';

import { Navigation, NavigationMobile } from '../components/Navigation'
import { Footer } from '../components/Footer'
import { BegreberComp } from '../components/Begreber'

export function Begreber() {
    return (
        <div className='app'>
            <div className='top'>
                <Navigation data={headerData}/>
                <h1 className='page_headline'><span className='orange_text'>Begreber</span> i erhvervsinformatik</h1>
                <BegreberComp />
            </div>
            <Footer />
            <Analytics />
        </div>
    )
}

export function BegreberMobile() {
    return (
        <div className='app'>
            <div className='top'>
                <NavigationMobile data={headerData}/>
                <h1 className='page_headline'><span className='orange_text'>Begreber</span> i erhvervsinformatik</h1>
                <BegreberComp />
            </div>
            <Footer />
            <Analytics />
        </div>
    )
}