export function BegreberComp() {
    return (
        <div>
            <div className="begreb_frame">
            <h1 className="begreb">Analyse af Digitale Artefakter</h1>
            <div className="udvidet_information">
                <h2 className="begreb_information">En kort definition af begrebet</h2>
                <p className="indepth_information">
                    Indsæt tekst her
                </p>
            </div>
            <div className="udvidet_information">
                <h2 className="begreb_information">En uddybning af begrebet</h2>
                <p className="indepth_information">
                    Begrebet dækker over en analysemodel med 4 underliggende kategorier: <br />
                    <div className="underpunkt">
                        <span className="bold">1. Teknologisk analyse</span> <br />
                        <div className="move_in">
                            <li>Udseende</li>
                            <p>i.    Form, farve, størrelse - det fysiske. Hjemmesider og apps har altså IKKE noget udseende</p>
                            <li>Funktionalitet</li>
                            <p>i.    Hvad den kan - altså hvilke funktioner den har</p>
                            <li>Brugergrænseflade</li>
                            <p>i.    Det der forbinder brugeren med teknologien - F.eks. skærmen</p>
                            <li>Input-teknologi</li>
                            <p>i.    Det data brugeren sender til artefaktet </p>
                            <li>Output-teknologi</li>
                            <p>i.    Det data eller output der sendes tilbage til brugeren</p>
                        </div>
                    </div>
                    <div className="underpunkt">
                        <span className="bold">2. Formåls analyse</span> <br />
                        <div className="move_in">
                           <li>Indsæt underkapitel her</li>
                            <p>i.    Indsæt tekst her</p> 
                        </div>
                        
                    </div>
                    <div className="underpunkt">
                        <span className="bold">3. Brugsstudie</span> <br />
                        <div className="move_in">
                            <li>Indsæt underkapitel her</li>
                            <p>i.    Indsæt tekst her</p>
                        </div> 
                    </div>
                    <div className="underpunkt">
                        <span className="bold">4. Konsekvensvurdering</span> <br />
                        <div className="move_in">
                            <li>Indsæt underkapitel her</li>
                            <p>i.    Indsæt tekst her</p>
                        </div>
                    </div>
                </p>
            </div>
            <div className="udvidet_information">
                <h2 className="begreb_information">Hvorfor er begrebet relevant?</h2>
                <p className="indepth_information">
                    Indsæt tekst her
                </p>
            </div>
            <div className="udvidet_information">
                <h2 className="begreb_information">Hvordan kan virksomheder eller individet bruge begrebet?</h2>
                <p className="indepth_information">
                    Indsæt tekst her
                </p>
            </div>
            <div className="udvidet_information">
                <h2 className="begreb_information">Videogennemgang - Analyse af Canva</h2>
                <div className="begreb_video_parent">
                    <div className="begreb_video">
                        <iframe 
                            src="https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=d26ec41b-2f21-4f62-ad18-12e0bfbae561&nav=%7B%22playbackOptions%22%3A%7B%22startTimeInSeconds%22%3A0%7D%7D&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create" 
                            width="640" 
                            height="360" 
                            frameborder="0" 
                            scrolling="no" 
                            allowfullscreen="true" 
                            title="ANALYSE AF DIGITALE ARTEFAKTER.mp4" 
                            className="begreb_video_iframe">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
        <div className="begreb_frame">
            <h1 className="begreb">Interaktionsdesign</h1>
            <div className="udvidet_information">
                <h2 className="begreb_information">En kort definition af begrebet</h2>
                <p className="indepth_information">
                    Interaktionsdesign er hvordan vi, ved hjælp af teorier og regler, designer hjemmesider <br/> og andre produkter, så de opfylder deres formål.
                </p>
            </div>
            <div className="udvidet_information">
                <h2 className="begreb_information">En uddybning af begrebet</h2>
                <p className="indepth_information">
                Interaktionsdesign er hvordan vi designer vores hjemmeside eller app - så designet støtter op om <br/> formålet med siden. Vil vi gerne sælge gennem siden? - Så skal siden designes så processen <br/> bliver så nem for brugeren som muligt. <br /><br /> Begrebet dækker over: 
                    <div className="underpunkt">
                        <span className="bold">Designregler</span> <br />
                        <div className="move_in">
                            <li>First Things First</li>
                            <p>i.    Vi placerer det vigtigste først - det brugeren skal vide står først og skal ikke gemmes væk</p>
                            <p>i.    De vigtigste overskrifter først</p>
                            <p>i.    De vigtigste ord først i sætningen</p>
                            <p>i.    Den vigtigste sætning først i brødteksten</p>
                            <p>i.    Det vigtigste først. Altid.</p>
                            <br />

                            <li>Keep it simple and straight (KISS)</li>
                            <p>i.    Hold det simpelt - alt det unødvendige skal væk</p>
                            <br />

                            <li>Gestaltlovene</li>
                            <p>i.    Loven om nærhed - Er noget tæt på hinanden, så opfattes det som sammenhørende</p>
                            <p>i.    Loven om lukkethed - Det der hegnes sammen har noget med hinanden at gøre</p>
                            <p>i.    Loven om lighed - Vi forventer at ting der minder om hinanden er det samme</p>
                            <p>i.    Loven om forbundethed - Binder vi noget sammen, så har det også noget tilfælles</p>
                        </div>
                    </div>
                    <div className="underpunkt">
                        <span className="bold">Fonte og farver</span> <br />
                        <div className="move_in">
                           <li>Fonte og farver forbindes med brandet - hold samme tema hele vejen igennem</li>
                            <p></p> 
                        </div>
                        
                    </div>
                </p>
            </div>
            <div className="udvidet_information">
                <h2 className="begreb_information">Hvorfor er begrebet relevant?</h2>
                <p className="indepth_information">
                    Begrebet er relevant at kende til, da det er teorien bag design og konstruktion af en successfuld hjemmeside eller anden platform. <br /> Med "successfuld" mener jeg, at formålet med siden bliver opfyldt. 
                </p>
            </div>
            <div className="udvidet_information">
                <h2 className="begreb_information">Hvordan kan virksomheder eller individet bruge begrebet?</h2>
                <p className="indepth_information">
                    Virksomheder kan bruge teorierne i deres designprocess, for at optimere deres side til netop det formålet med den er. <br /> Hvis formålet er at konvertere flere leads, kan indholdet altså optimeres, så siden er brugervenlig på en sådan måde, <br /> at det konverterer flere leads.
                </p>
            </div>
            <div className="udvidet_information">
                <h2 className="begreb_information">Eksempel - Brug af designregler ved Zalando</h2>
                <p className="indepth_information">
                    Nedenfor ses en illustration over steder på Zalandos hjemmesiden, hvor der er gjort brug af designregler.
                </p>
                <div className="begreb_video_parent">
                    <div className="begreb_video">
                        <iframe 
                            src="https://campusvejle-my.sharepoint.com/personal/57656_edu_campusvejle_dk/_layouts/15/embed.aspx?UniqueId=15b69ed2-8767-4645-9d5e-0a6b06863aff" 
                            width="617" 
                            height="360" 
                            frameborder="0" 
                            scrolling="no" 
                            allowfullscreen 
                            draggable="false"
                            title="Designregler - Et eksempel.png"
                            className="begreb_video_iframe">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default BegreberComp